import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import ProjectList from '../components/projectList'

const ProjectsPage = () => {
    return (
        <Layout>
            <Seo title="Projects" />

            <section className="hero is-bold is-primary">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">Projects I've done</h1>
                    </div>
                </div>
            </section>

            <ProjectList />
        </Layout>
    )
}

export default ProjectsPage