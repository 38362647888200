import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const ProjectList = () => {
    return (
        <StaticQuery 
            query={graphql`
                {
                    allMarkdownRemark(
                        filter: {frontmatter: {path: {ne: null}}}
                        sort: {order: DESC, fields: frontmatter___date}
                    ) {
                        edges {
                            node {
                                frontmatter {
                                    path
                                    title
                                    subtitle
                                    tags
                                    image
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section className="section">
                    <div className="container">
                        {data.allMarkdownRemark.edges.map(({ node }) => (
                            <div className="box" key={node.frontmatter.path}>
                                <article className="media is-stacked-mobile">
                                    <div className="media-left">
                                        <figure className="image is-128x128">
                                            <img alt="" src={node.frontmatter.image} />
                                        </figure>
                                    </div>

                                    <div className="media-content">
                                        <h1 className="title is-4">{node.frontmatter.title}</h1>
                                        <div className="content">{node.frontmatter.subtitle}</div>
                                        <nav className="level">
                                            <div className="level-left">
                                                <span className="tags">
                                                    {node.frontmatter.tags.map((tag) => (
                                                        <span className="tag is-dark" key={tag}>{tag}</span>
                                                    ))}
                                                </span>
                                            </div>
                                            <div className="level-right">
                                                <Link to={node.frontmatter.path} className="button is-primary is-outlined">
                                                    Learn more
                                                </Link>
                                            </div>
                                        </nav>
                                    </div>
                                </article>
                            </div>
                        ))}
                    </div>
                </section>
            )}
        />
    )
}

export default ProjectList